import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

import DayOfWeekGrid from "../day-of-week-grid";
import { DayOfWeek } from "../../utils";
import styles from "./styles.module.scss";
import Share from "../share";

interface IMainContentProps {
	selectedDaysOfWeek: DayOfWeek[];
	onSelectedDaysOfWeekChange: (
		checked: boolean,
		dayOfWeek: DayOfWeek
	) => void;
	onImprintClicked: () => void;
	onPrivacyClicked: () => void;
}

const MainContent = ({
	selectedDaysOfWeek,
	onSelectedDaysOfWeekChange,
	onImprintClicked,
	onPrivacyClicked
}: IMainContentProps) => {
	console.log("selected days: ", selectedDaysOfWeek);
	return (
		<>
			<p>
				Du hast Bock auf eine Halbe Henne mit Brot? Du bist dir nie
				sicher wann und wo der berühmt berüchtigte "Hännile Stand"
				steht? Dann bist du hier genau richtig!
			</p>
			<p>
				Wir haben dir alle uns bekannten Standorte und Wochentage, an
				denen du deine Lust auf Hähnchen befriedigen kannst, auf einer
				übersichtlichen Karte zusammengetragen!
			</p>
			<p className="mb-large">
				Wenn du einen Standort kennst der noch nicht eingetragen ist,
				oder ein Eintrag nicht mehr aktuell ist, schreib uns einfach
				eine <a href="mailto:info@codenite.at">Mail!</a>
			</p>
			<div className={classNames(styles.filterContainer, "mb-huge")}>
				<h2 className="mb-small">
					<FontAwesomeIcon
						icon={faCalendarAlt}
						size="sm"
						style={{ marginRight: 10 }}
					/>
					Wochentage:
				</h2>
				<DayOfWeekGrid
					selectedDaysOfWeek={selectedDaysOfWeek}
					onSelectedDaysOfWeekChange={onSelectedDaysOfWeekChange}
				/>
			</div>
			<footer className={styles.footer}>
				<div className="mb-small">
					<Share />
				</div>
				<div>
					<a onClick={onPrivacyClicked}>Datenschutz</a> |{" "}
					<a onClick={onImprintClicked}>Impressum</a>
				</div>
			</footer>
		</>
	);
};

export default MainContent;
