import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Cookies from "js-cookie";

import styles from "./styles.module.scss";

const COOKIE_NAME = "gatsby-gdpr-google-analytics";

const CookieConsent = () => {
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		const cookieValue = Cookies.get(COOKIE_NAME);

		if (cookieValue == null) {
			setVisible(true);
		}
	}, []);

	const setCookieConsent = (value: boolean) => {
		const secure = location ? location.protocol === "https" : true;

		Cookies.set(COOKIE_NAME, String(value), {
			expires: 365,
			secure,
			sameSite: "lax"
		});
		setVisible(false);
	};

	if (visible === false) {
		return null;
	}

	return (
		<div className={styles.container}>
			<div className={styles.text}>
				Cookies helfen uns dabei, unsere Website optimal zu gestalten
				und zu verbessern. Durch die Ablehnung könnte die Funktion der
				Website beeinträchtigt werden.
			</div>
			<div className={styles.buttonRow}>
				<button
					className={classNames(styles.button, styles.declineButton)}
					onClick={() => setCookieConsent(false)}
				>
					Ablehnen
				</button>
				<button
					className={classNames(styles.button, styles.acceptButton)}
					onClick={() => setCookieConsent(true)}
				>
					Akzeptieren
				</button>
			</div>
		</div>
	);
};

export default CookieConsent;
