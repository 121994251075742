import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useLocation } from "@reach/router";
import { Helmet } from "react-helmet";

const SEO = () => {
	const location = useLocation();
	const { site } = useStaticQuery(query);

	const { title, description, siteUrl } = site.siteMetadata;

	const seo = {
		title,
		description,
		url: `${siteUrl}${location.pathname}`
	};

	return (
		<Helmet title={seo.title}>
			<meta charSet="utf-8" />
			<html lang="de" dir="ltr" />
			<meta
				name="viewport"
				content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
			/>

			<meta name="description" content={seo.description}></meta>
			{seo.url && <meta property="og:url" content={seo.url} />}
			{seo.title && <meta property="og:title" content={seo.title} />}
			{seo.description && (
				<meta property="og:description" content={seo.description} />
			)}

			<meta
				name="og:image"
				content="https://www.halbe-henn.at/halbe_henn_og.png"
			/>

			<meta
				name="og:image:secure_url"
				content="https://www.halbe-henn.at/halbe_henn_og.png"
			/>

			<meta name="og:image:alt" content={title} />

			<meta name="og:image:width" content="1200" />
			<meta name="og:image:height" content="627" />

			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:site" content="@CodeniteD" />
			{seo.title && <meta property="twitter:title" content={seo.title} />}
			{seo.description && (
				<meta
					property="twitter:description"
					content={seo.description}
				/>
			)}

			<meta name="robots" content="index, follow" />
			<link rel="canonical" href={seo.url} />
		</Helmet>
	);
};

export default SEO;

const query = graphql`
	query SEO {
		site {
			siteMetadata {
				title
				description
				siteUrl
			}
		}
	}
`;
