import React from "react";
import {
	FacebookShareButton,
	FacebookIcon,
	TwitterShareButton,
	TwitterIcon,
	LinkedinShareButton,
	LinkedinIcon,
	TelegramShareButton,
	TelegramIcon,
	WhatsappShareButton,
	WhatsappIcon
} from "react-share";

import styles from "./styles.module.scss";

const Share = () => {
	const url = "https://halbe-henn.at";
	const quote = "Finde jetzt den nächsten Hähnchen Stand in deiner Nähe!";
	const iconSize = 30;

	return (
		<div>
			<FacebookShareButton
				quote={quote}
				url={url}
				className={styles.icon}
			>
				<FacebookIcon size={iconSize} borderRadius={iconSize} />
			</FacebookShareButton>
			<TwitterShareButton
				title={quote}
				hashtags={["vorarlberg", "hähnchen", "halbehenn"]}
				url={url}
				className={styles.icon}
			>
				<TwitterIcon size={iconSize} borderRadius={iconSize} />
			</TwitterShareButton>
			<LinkedinShareButton
				title={quote}
				url={url}
				className={styles.icon}
			>
				<LinkedinIcon size={iconSize} borderRadius={iconSize} />
			</LinkedinShareButton>
			<TelegramShareButton
				url={url}
				className={styles.icon}
				title={quote}
			>
				<TelegramIcon size={iconSize} borderRadius={iconSize} />
			</TelegramShareButton>
			<WhatsappShareButton
				url={url}
				className={styles.icon}
				title={quote}
			>
				<WhatsappIcon size={iconSize} borderRadius={iconSize} />
			</WhatsappShareButton>
		</div>
	);
};

export default Share;
