import React from "react";
import classNames from "classnames";

import { DayOfWeek, dayOfWeekArray } from "../../utils";
import SelectBox from "../select-box";
import styles from "./styles.module.scss";

interface IDayOfWeekGridProps {
	compact?: boolean;
	selectedDaysOfWeek: DayOfWeek[];
	onSelectedDaysOfWeekChange: (
		checked: boolean,
		dayOfWeek: DayOfWeek
	) => void;
}

const DayOfWeekGrid = ({
	compact = false,
	selectedDaysOfWeek,
	onSelectedDaysOfWeekChange
}: IDayOfWeekGridProps) => {
	const isDayOfWeekChecked = (dayOfWeek: DayOfWeek) => {
		return selectedDaysOfWeek.includes(dayOfWeek);
	};

	return (
		<div className={classNames(styles.grid, { [styles.compact]: compact })}>
			{dayOfWeekArray.map((dayOfWeek) => (
				<SelectBox
					key={dayOfWeek.value}
					compact={compact}
					selected={isDayOfWeekChecked(dayOfWeek.value)}
					label={dayOfWeek.label}
					onChange={(checked) =>
						onSelectedDaysOfWeekChange(checked, dayOfWeek.value)
					}
				/>
			))}
		</div>
	);
};

export default DayOfWeekGrid;
