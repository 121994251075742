import L from "leaflet";

let chickenMarker = undefined;

const markerSize = 75;

if (typeof window !== `undefined`) {
	chickenMarker = L.icon({
		iconUrl: require("../assets/images/chicken_marker.svg"),
		iconRetinaUrl: require("../assets/images/chicken_marker.svg"),
		iconSize: [markerSize, markerSize],
		iconAnchor: [markerSize / 2, markerSize],
		popupAnchor: [0, -markerSize]
	});
}

export default chickenMarker;
