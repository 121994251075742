import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronLeft,
	faEnvelope,
	faGlobe
} from "@fortawesome/free-solid-svg-icons";

import CodeniteLogo from "../../assets/images/codenite_logo.png";

interface IImprintProps {
	onBackClicked: () => void;
}

const Imprint = ({ onBackClicked }: IImprintProps) => {
	return (
		<div>
			<div
				className="mb-medium a"
				onClick={onBackClicked}
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center"
				}}
			>
				<FontAwesomeIcon
					icon={faChevronLeft}
					style={{ marginRight: 5 }}
				/>
				<div>Zurück</div>
			</div>

			<div style={{ width: "6rem" }} className="mb-micro">
				<img src={CodeniteLogo} style={{ width: "100%" }} />
			</div>
			<h2 className="mb-small">Codenite Development GesbR</h2>
			<p>
				Software-, Web- und App-Entwicklung
				<br />
				Gütlestraße 7a, 6850 Dornbirn
				<br />
				UID: ATU73337356
				<br />
				<br />
				<a href="mailto:info@codenite.at">
					<FontAwesomeIcon
						icon={faEnvelope}
						style={{ marginRight: 5 }}
					/>
					info@codenite.at
				</a>
				<br />
				<a href="https://codenite.at" target="_blank">
					<FontAwesomeIcon
						icon={faGlobe}
						style={{ marginRight: 5 }}
					/>
					https://codenite.at
				</a>
			</p>
			<p>
				Mitglieder der WKO, Fachgruppe: Unternehmensberatung,
				Buchhaltung und Informationstechnologie - Vorarlberg
			</p>
			<p>
				Verbraucher haben die Möglichkeit, Beschwerden an die
				Online-Streitbeilegungsplattform der EU zu richten:
				<a href="http://ec.europa.eu/odr" target="_blank">
					http://ec.europa.eu/odr
				</a>
				. Sie können allfällige Beschwerden auch an die oben angegebene
				E-Mail-Adresse richten.
			</p>
			<p></p>
			<p>
				<span>
					Header Photo by{" "}
					<a href="https://unsplash.com/@tjsocoz?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
						Tim Bish
					</a>{" "}
					on{" "}
					<a href="https://unsplash.com/s/photos/chicken?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
						Unsplash
					</a>
				</span>
				<br />
				<span>
					Icons made by{" "}
					<a
						href="https://www.flaticon.com/authors/freepik"
						title="Freepik"
					>
						Freepik
					</a>{" "}
					from{" "}
					<a href="https://www.flaticon.com/" title="Flaticon">
						{" "}
						www.flaticon.com
					</a>
				</span>
			</p>
		</div>
	);
};

export default Imprint;
