import L, { LatLngBounds, LatLngLiteral } from "leaflet";

export enum DayOfWeek {
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6
}

export const dayOfWeekArray = [
	{
		value: DayOfWeek.Monday,
		label: "Montag"
	},
	{
		value: DayOfWeek.Tuesday,
		label: "Dienstag"
	},
	{
		value: DayOfWeek.Wednesday,
		label: "Mittwoch"
	},
	{
		value: DayOfWeek.Thursday,
		label: "Donnerstag"
	},
	{
		value: DayOfWeek.Friday,
		label: "Freitag"
	},
	{
		value: DayOfWeek.Saturday,
		label: "Samstag"
	}
];

export const createBounds = (p1: LatLngLiteral, p2: LatLngLiteral) => {
	if (typeof window !== `undefined`) {
		return new LatLngBounds(p1, p2);
	}
	return undefined;
};
