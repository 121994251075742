import React, { useState, useEffect } from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import { LatLngBounds, LatLngLiteral } from "leaflet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import Div100vh from "react-div-100vh";

import { createBounds, DayOfWeek, dayOfWeekArray } from "../utils";
import chickenMarker from "../utils/chicken-marker";
import styles from "./styles.module.scss";
import Locations from "../../content/locations.json";

import Menu from "../components/menu";
import QuickFilter from "../components/quick-filter";
import CookieConsent from "../components/cookie-consent";
import SEO from "../components/seo";

const Home = () => {
	const corner1: LatLngLiteral = { lat: 47.59984, lng: 9.464651 };
	const corner2: LatLngLiteral = { lat: 46.846622, lng: 10.39344 };
	const defaultBounds: LatLngBounds = createBounds(corner1, corner2);

	const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState([]);

	useEffect(() => {
		const currentDayOfWeek = new Date().getDay();

		let initialWeekdays = [];

		if (currentDayOfWeek > 0) {
			initialWeekdays = [currentDayOfWeek];
		} else {
			initialWeekdays = [1, 2, 3, 4, 5, 6];
		}

		setSelectedDaysOfWeek(initialWeekdays);
	}, []);

	const handleCheckboxChanged = (checked: boolean, dayOfWeek: DayOfWeek) => {
		if (checked && !selectedDaysOfWeek.includes(dayOfWeek)) {
			setSelectedDaysOfWeek([...selectedDaysOfWeek, dayOfWeek]);
		} else if (!checked && selectedDaysOfWeek.includes(dayOfWeek)) {
			setSelectedDaysOfWeek(
				selectedDaysOfWeek.filter((x) => x !== dayOfWeek)
			);
		}
	};

	return (
		<Div100vh className={styles.container}>
			<SEO />
			<Map className={styles.map} bounds={defaultBounds}>
				<TileLayer
					attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				{Locations.filter((x) =>
					x.daysOfWeek.some((dayOfWeek) =>
						selectedDaysOfWeek.includes(dayOfWeek)
					)
				).map((location) => (
					<Marker
						key={location.name}
						position={location.coordinates}
						icon={chickenMarker}
					>
						<Popup className={styles.popupContainer}>
							<div className={styles.popupTitle}>
								{location.name}
							</div>
							<div className={styles.popupAddress}>
								{location.address}
							</div>
							<div>
								{dayOfWeekArray.map((weekday, index) => {
									const included = location.daysOfWeek.includes(
										index + 1
									);

									return (
										<div
											className={styles.weekDayContainer}
											key={index}
										>
											<FontAwesomeIcon
												icon={
													included ? faCheck : faTimes
												}
												fixedWidth
												className={
													included
														? styles.available
														: styles.notAvailable
												}
											/>
											{weekday.label}
										</div>
									);
								})}
							</div>
						</Popup>
					</Marker>
				))}
			</Map>
			<QuickFilter
				selectedDaysOfWeek={selectedDaysOfWeek}
				onSelectedDaysOfWeekChange={handleCheckboxChanged}
			/>

			<Menu
				selectedDaysOfWeek={selectedDaysOfWeek}
				onSelectedDaysOfWeekChange={handleCheckboxChanged}
			/>
			<CookieConsent />
		</Div100vh>
	);
};

export default Home;
