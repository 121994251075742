import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import styles from "./styles.module.scss";
import { DayOfWeek } from "../../utils";
import DayOfWeekGrid from "../day-of-week-grid";

const QUICK_FILTER_OPEN_KEY = "quickFilterOpenKey";

interface IQuickFilterProps {
	selectedDaysOfWeek: DayOfWeek[];
	onSelectedDaysOfWeekChange: (
		checked: boolean,
		dayOfWeek: DayOfWeek
	) => void;
}

const QuickFilter = ({
	selectedDaysOfWeek,
	onSelectedDaysOfWeekChange
}: IQuickFilterProps) => {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		const openFromStorage = localStorage.getItem(QUICK_FILTER_OPEN_KEY);

		if (openFromStorage === null) {
			localStorage.setItem(QUICK_FILTER_OPEN_KEY, "true");
			setOpen(true);
		} else if (openFromStorage === "true") {
			setOpen(true);
		}
	}, []);

	const toggle = () => {
		const newValue = !open;
		setOpen(!open);
		localStorage.setItem(
			QUICK_FILTER_OPEN_KEY,
			newValue === true ? "true" : "false"
		);
	};

	return (
		<div
			className={classNames(styles.container, {
				[styles.open]: open
			})}
		>
			<div className={styles.filterContainer}>
				<DayOfWeekGrid
					compact
					selectedDaysOfWeek={selectedDaysOfWeek}
					onSelectedDaysOfWeekChange={onSelectedDaysOfWeekChange}
				></DayOfWeekGrid>
			</div>
			<div className={styles.toggleBox} onClick={toggle}>
				<FontAwesomeIcon
					icon={faChevronRight}
					className={classNames(styles.filterIcon, {
						[styles.open]: open
					})}
				/>
				<div>Tag-Filter</div>
			</div>
		</div>
	);
};

export default QuickFilter;
