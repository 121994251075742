import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

interface IPrivacyProps {
	onBackClicked: () => void;
}

const Privacy = ({ onBackClicked }: IPrivacyProps) => {
	return (
		<div>
			<div
				className="mb-medium a"
				onClick={onBackClicked}
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center"
				}}
			>
				<FontAwesomeIcon
					icon={faChevronLeft}
					style={{ marginRight: 5 }}
				/>
				<div>Zurück</div>
			</div>
			<h2 className="mb-medium big">Datenschutzerklärung</h2>
			<h3 id="datenschutz">Datenschutz</h3>
			<p>
				Wir haben diese Datenschutzerklärung (Fassung
				19.11.2020-111478131) verfasst, um Ihnen gemäß der Vorgaben der{" "}
				<a
					href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					Datenschutz-Grundverordnung (EU) 2016/679
				</a>{" "}
				zu erklären, welche Informationen wir sammeln, wie wir Daten
				verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher
				dieser Webseite haben.
			</p>
			<p>
				Leider liegt es in der Natur der Sache, dass diese Erklärungen
				sehr technisch klingen, wir haben uns bei der Erstellung jedoch
				bemüht die wichtigsten Dinge so einfach und klar wie möglich zu
				beschreiben.
			</p>
			<h3 id="automatische-datenspeicherung">
				Automatische Datenspeicherung
			</h3>
			<p>
				Wenn Sie heutzutage Webseiten besuchen, werden gewisse
				Informationen automatisch erstellt und gespeichert, so auch auf
				dieser Webseite.
			</p>
			<p>
				Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert
				unser Webserver (Computer auf dem diese Webseite gespeichert
				ist) automatisch Daten wie
			</p>
			<ul>
				<li>die Adresse (URL) der aufgerufenen Webseite</li>
				<li>Browser und Browserversion</li>
				<li>das verwendete Betriebssystem</li>
				<li>
					die Adresse (URL) der zuvor besuchten Seite (Referrer URL)
				</li>
				<li>
					den Hostname und die IP-Adresse des Geräts von welchem aus
					zugegriffen wird
				</li>
				<li>Datum und Uhrzeit</li>
			</ul>
			<p>in Dateien (Webserver-Logfiles).</p>
			<p>
				In der Regel werden Webserver-Logfiles zwei Wochen gespeichert
				und danach automatisch gelöscht. Wir geben diese Daten nicht
				weiter, können jedoch nicht ausschließen, dass diese Daten beim
				Vorliegen von rechtswidrigem Verhalten eingesehen werden.
			</p>
			<h3 id="cookies">Cookies</h3>
			<p>
				Unsere Webseite verwendet HTTP-Cookies, um nutzerspezifische
				Daten zu speichern.
				<br />
				Im Folgenden erklären wir, was Cookies sind und warum Sie
				genutzt werden, damit Sie die folgende Datenschutzerklärung
				besser verstehen.
			</p>
			<h4>Was genau sind Cookies?</h4>
			<p>
				Immer wenn Sie durch das Internet surfen, verwenden Sie einen
				Browser. Bekannte Browser sind beispielsweise Chrome, Safari,
				Firefox, Internet Explorer und Microsoft Edge. Die meisten
				Webseiten speichern kleine Text-Dateien in Ihrem Browser. Diese
				Dateien nennt man Cookies.
			</p>
			<p>
				Eines ist nicht von der Hand zu weisen: Cookies sind echt
				nützliche Helferlein. Fast alle Webseiten verwenden Cookies.
				Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere
				Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind
				kleine Dateien, die von unserer Webseite auf Ihrem Computer
				gespeichert werden. Diese Cookie-Dateien werden automatisch im
				Cookie-Ordner, quasi dem “Hirn” Ihres Browsers, untergebracht.
				Ein Cookie besteht aus einem Namen und einem Wert. Bei der
				Definition eines Cookies müssen zusätzlich ein oder mehrere
				Attribute angegeben werden.
			</p>
			<p>
				Cookies speichern gewisse Nutzerdaten von Ihnen, wie
				beispielsweise Sprache oder persönliche Seiteneinstellungen.
				Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser
				die „userbezogenen“ Informationen an unsere Seite zurück. Dank
				der Cookies weiß unsere Webseite, wer Sie sind und bietet Ihnen
				die Einstellung, die Sie gewohnt sind. In einigen Browsern hat
				jedes Cookie eine eigene Datei, in anderen wie beispielsweise
				Firefox sind alle Cookies in einer einzigen Datei gespeichert.
			</p>
			<p>
				Es gibt sowohl Erstanbieter Cookies als auch
				Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von
				unserer Seite erstellt, Drittanbieter-Cookies werden von
				Partner-Webseiten (z.B. Google Analytics) erstellt. Jedes Cookie
				ist individuell zu bewerten, da jedes Cookie andere Daten
				speichert. Auch die Ablaufzeit eines Cookies variiert von ein
				paar Minuten bis hin zu ein paar Jahren. Cookies sind keine
				Software-Programme und enthalten keine Viren, Trojaner oder
				andere „Schädlinge“. Cookies können auch nicht auf Informationen
				Ihres PCs zugreifen.
			</p>
			<p>So können zum Beispiel Cookie-Daten aussehen:</p>
			<p>
				<strong>Name:</strong> _ga
				<br />
				<strong>Wert:</strong>
				 GA1.2.1326744211.152111478131-9
				<br />
				<strong>Verwendungszweck:</strong> Unterscheidung der
				Webseitenbesucher
				<br />
				<strong>Ablaufdatum:</strong> nach 2 Jahren
			</p>
			<p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
			<ul>
				<li>Mindestens 4096 Bytes pro Cookie</li>
				<li>Mindestens 50 Cookies pro Domain</li>
				<li>Mindestens 3000 Cookies insgesamt</li>
			</ul>
			<h4>Welche Arten von Cookies gibt es?</h4>
			<p>
				Die Frage welche Cookies wir im Speziellen verwenden, hängt von
				den verwendeten Diensten ab und wird in den folgenden
				Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle
				möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies
				eingehen.
			</p>
			<p>Man kann 4 Arten von Cookies unterscheiden:</p>
			<p>
				<strong>
					Unerlässliche Cookies
					<br />
				</strong>
				Diese Cookies sind nötig, um grundlegende Funktionen der
				Webseite sicherzustellen. Zum Beispiel braucht es diese Cookies,
				wenn ein User ein Produkt in den Warenkorb legt, dann auf
				anderen Seiten weitersurft und später erst zur Kasse geht. Durch
				diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der
				User sein Browserfenster schließt.
			</p>
			<p>
				<strong>
					Zweckmäßige Cookies
					<br />
				</strong>
				Diese Cookies sammeln Infos über das Userverhalten und ob der
				User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe
				dieser Cookies auch die Ladezeit und das Verhalten der Webseite
				bei verschiedenen Browsern gemessen.
			</p>
			<p>
				<strong>
					Zielorientierte Cookies
					<br />
				</strong>
				Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit.
				Beispielsweise werden eingegebene Standorte, Schriftgrößen oder
				Formulardaten gespeichert.
			</p>
			<p>
				<strong>
					Werbe-Cookies
					<br />
				</strong>
				Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen
				dazu dem User individuell angepasste Werbung zu liefern. Das
				kann sehr praktisch, aber auch sehr nervig sein.
			</p>
			<p>
				Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite
				gefragt, welche dieser Cookiearten Sie zulassen möchten. Und
				natürlich wird diese Entscheidung auch in einem Cookie
				gespeichert.
			</p>
			<h4>Wie kann ich Cookies löschen?</h4>
			<p>
				Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst.
				Unabhängig von welchem Service oder welcher Webseite die Cookies
				stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu
				deaktivieren oder nur teilweise zuzulassen. Zum Beispiel können
				Sie Cookies von Drittanbietern blockieren, aber alle anderen
				Cookies zulassen.
			</p>
			<p>
				Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser
				gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder
				löschen wollen, können Sie dies in Ihren Browser-Einstellungen
				finden:
			</p>
			<p>
				<a
					href="https://support.google.com/chrome/answer/95647?tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					Chrome: Cookies in Chrome löschen, aktivieren und verwalten
				</a>
			</p>
			<p>
				<a
					href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					Safari: Verwalten von Cookies und Websitedaten mit Safari
				</a>
			</p>
			<p>
				<a
					href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					Firefox: Cookies löschen, um Daten zu entfernen, die
					Websites auf Ihrem Computer abgelegt haben
				</a>
			</p>
			<p>
				<a
					href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					Internet Explorer: Löschen und Verwalten von Cookies
				</a>
			</p>
			<p>
				<a
					href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					Microsoft Edge: Löschen und Verwalten von Cookies
				</a>
			</p>
			<p>
				Falls Sie grundsätzlich keine Cookies haben wollen, können Sie
				Ihren Browser so einrichten, dass er Sie immer informiert, wenn
				ein Cookie gesetzt werden soll. So können Sie bei jedem
				einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder
				nicht. Die Vorgangsweise ist je nach Browser verschieden. Am
				besten Sie suchen die Anleitung in Google mit dem Suchbegriff
				“Cookies löschen Chrome” oder “Cookies deaktivieren Chrome” im
				Falle eines Chrome Browsers.
			</p>
			<h4>Wie sieht es mit meinem Datenschutz aus?</h4>
			<p>
				Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin
				ist festgehalten, dass das Speichern von Cookies eine
				Einwilligung von Ihnen verlangt. Innerhalb der EU-Länder gibt es
				allerdings noch sehr unterschiedliche Reaktionen auf diese
				Richtlinien. In Österreich erfolgte aber die Umsetzung dieser
				Richtlinie in § 96 Abs. 3 des Telekommunikationsgesetzes (TKG).
			</p>
			<p>
				Wenn Sie mehr über Cookies wissen möchten und technische
				Dokumentationen nicht scheuen, empfehlen wir{" "}
				<a
					href="https://tools.ietf.org/html/rfc6265"
					target="_blank"
					rel="nofollow noopener noreferrer"
				>
					https://tools.ietf.org/html/rfc6265
				</a>
				, dem Request for Comments der Internet Engineering Task Force
				(IETF) namens “HTTP State Management Mechanism”.
			</p>
			<h3 id="rechte-dsgvo">Rechte laut Datenschutzgrundverordnung</h3>
			<p>
				Ihnen stehen laut den Bestimmungen der DSGVO und des
				österreichischen{" "}
				<a
					href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10001597&tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					Datenschutzgesetzes (DSG)
				</a>{" "}
				grundsätzlich die folgende Rechte zu:
			</p>
			<ul>
				<li>Recht auf Berichtigung (Artikel 16 DSGVO)</li>
				<li>
					Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17
					DSGVO)
				</li>
				<li>
					Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)
				</li>
				<li>
					Recht auf Benachrichtigung – Mitteilungspflicht im
					Zusammenhang mit der Berichtigung oder Löschung
					personenbezogener Daten oder der Einschränkung der
					Verarbeitung (Artikel 19 DSGVO)
				</li>
				<li>Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
				<li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
				<li>
					Recht, nicht einer ausschließlich auf einer automatisierten
					Verarbeitung — einschließlich Profiling — beruhenden
					Entscheidung unterworfen zu werden (Artikel 22 DSGVO)
				</li>
			</ul>
			<p>
				Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
				Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen
				Ansprüche sonst in einer Weise verletzt worden sind, können Sie
				sich bei der Aufsichtsbehörde beschweren, welche in Österreich
				die Datenschutzbehörde ist, deren Webseite Sie unter{" "}
				<a href="https://www.dsb.gv.at/?tid=111478131">
					https://www.dsb.gv.at/
				</a>{" "}
				finden.
			</p>
			<h3 id="auswertung-des-besucherverhaltens">
				Auswertung des Besucherverhaltens
			</h3>
			<p>
				In der folgenden Datenschutzerklärung informieren wir Sie
				darüber, ob und wie wir Daten Ihres Besuchs dieser Website
				auswerten. Die Auswertung der gesammelten Daten erfolgt in der
				Regel anonym und wir können von Ihrem Verhalten auf dieser
				Website nicht auf Ihre Person schließen.
			</p>
			<p>
				Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu
				widersprechen erfahren Sie in der folgenden
				Datenschutzerklärung.
			</p>
			<h3 id="tls-verschluesselung-https">
				TLS-Verschlüsselung mit https
			</h3>
			<p>
				Wir verwenden https um Daten abhörsicher im Internet zu
				übertragen (Datenschutz durch Technikgestaltung{" "}
				<a
					href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					Artikel 25 Absatz 1 DSGVO
				</a>
				). Durch den Einsatz von TLS (Transport Layer Security), einem
				Verschlüsselungsprotokoll zur sicheren Datenübertragung im
				Internet können wir den Schutz vertraulicher Daten
				sicherstellen. Sie erkennen die Benutzung dieser Absicherung der
				Datenübertragung am kleinen Schlosssymbol links oben im Browser
				und der Verwendung des Schemas https (anstatt http) als Teil
				unserer Internetadresse.
			</p>
			<h3 id="google-fonts-datenschutzerklaerung">
				Google Fonts Datenschutzerklärung
			</h3>
			<p>
				Auf unserer Website verwenden wir Google Fonts. Das sind die
				“Google-Schriften” der Firma Google Inc. Für den europäischen
				Raum ist das Unternehmen Google Ireland Limited (Gordon House,
				Barrow Street Dublin 4, Irland) für alle Google-Dienste
				verantwortlich.
			</p>
			<p>
				Für die Verwendung von Google-Schriftarten müssen Sie sich nicht
				anmelden bzw. ein Passwort hinterlegen. Weiters werden auch
				keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS,
				Schriftarten/Fonts) werden über die Google-Domains
				fonts.googleapis.com und fonts.gstatic.com angefordert. Laut
				Google sind die Anfragen nach CSS und Schriften vollkommen
				getrennt von allen anderen Google-Diensten. Wenn Sie ein
				Google-Konto haben, brauchen Sie keine Sorge haben, dass Ihre
				Google-Kontodaten, während der Verwendung von Google Fonts, an
				Google übermittelt werden. Google erfasst die Nutzung von CSS
				(Cascading Style Sheets) und der verwendeten Schriftarten und
				speichert diese Daten sicher. Wie die Datenspeicherung genau
				aussieht, werden wir uns noch im Detail ansehen.
			</p>
			<h4>Was sind Google Fonts?</h4>
			<p>
				Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit
				über 800 Schriftarten, die{" "}
				<a href="https://de.wikipedia.org/wiki/Google_LLC?tid=111478131">
					Google
				</a>
				 Ihren Nutzern kostenlos zu Verfügung stellen.
			</p>
			<p>
				Viele dieser Schriftarten sind unter der SIL Open Font
				License veröffentlicht, während andere unter
				der Apache-Lizenz veröffentlicht wurden. Beides sind freie
				Software-Lizenzen.
			</p>
			<h4>Warum verwenden wir Google Fonts auf unserer Webseite?</h4>
			<p>
				Mit Google Fonts können wir auf der eigenen Webseite Schriften
				nutzen, und müssen sie nicht auf unserem eigenen Server
				hochladen. Google Fonts ist ein wichtiger Baustein, um die
				Qualität unserer Webseite hoch zu halten. Alle Google-Schriften
				sind automatisch für das Web optimiert und dies spart
				Datenvolumen und ist speziell für die Verwendung bei mobilen
				Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen,
				sorgt die niedrige Dateigröße für eine schnelle Ladezeit. Des
				Weiteren sind Google Fonts sichere Web Fonts. Unterschiedliche
				Bildsynthese-Systeme (Rendering) in verschiedenen Browsern,
				Betriebssystemen und mobilen Endgeräten können zu Fehlern
				führen. Solche Fehler können teilweise Texte bzw. ganze
				Webseiten optisch verzerren. Dank des schnellen Content Delivery
				Network (CDN) gibt es mit Google Fonts keine
				plattformübergreifenden Probleme. Google Fonts unterstützt alle
				gängigen Browser (Google Chrome, Mozilla Firefox, Apple
				Safari, Opera) und funktioniert zuverlässig auf den meisten
				modernen mobilen Betriebssystemen, einschließlich Android 2.2+
				und iOS 4.2+ (iPhone, iPad, iPod). Wir verwenden die Google
				Fonts also, damit wir unser gesamtes Online-Service so schön und
				einheitlich wie möglich darstellen können.
			</p>
			<h4>Welche Daten werden von Google gespeichert?</h4>
			<p>
				Wenn Sie unsere Webseite besuchen, werden die Schriften über
				einen Google-Server nachgeladen. Durch diesen externen Aufruf
				werden Daten an die Google-Server übermittelt. So erkennt Google
				auch, dass Sie bzw. Ihre IP-Adresse unsere Webseite besucht. Die
				Google Fonts API wurde entwickelt, um Verwendung, Speicherung
				und Erfassung von Endnutzerdaten auf das zu reduzieren, was für
				eine ordentliche Bereitstellung von Schriften nötig ist. API
				steht übrigens für „Application Programming Interface“ und dient
				unter anderem als Datenübermittler im Softwarebereich.
			</p>
			<p>
				Google Fonts speichert CSS- und Schrift-Anfragen sicher bei
				Google und ist somit geschützt. Durch die gesammelten
				Nutzungszahlen kann Google feststellen, wie gut die einzelnen
				Schriften ankommen. Die Ergebnisse veröffentlicht Google auf
				internen Analyseseiten, wie beispielsweise Google Analytics.
				Zudem verwendet Google auch Daten des eigenen Web-Crawlers, um
				festzustellen, welche Webseiten Google-Schriften verwenden.
				Diese Daten werden in der BigQuery-Datenbank von Google Fonts
				veröffentlicht. Unternehmer und Entwickler nützen das
				Google-Webservice BigQuery, um große Datenmengen untersuchen und
				bewegen zu können.
			</p>
			<p>
				Zu bedenken gilt allerdings noch, dass durch jede Google Font
				Anfrage auch Informationen wie Spracheinstellungen, IP-Adresse,
				Version des Browsers, Bildschirmauflösung des Browsers und Name
				des Browsers automatisch an die Google-Server übertragen werden.
				Ob diese Daten auch gespeichert werden, ist nicht klar
				feststellbar bzw. wird von Google nicht eindeutig kommuniziert.
			</p>
			<h4>Wie lange und wo werden die Daten gespeichert?</h4>
			<p>
				Anfragen für CSS-Assets speichert Google einen Tag lang auf
				seinen Servern, die hauptsächlich außerhalb der EU angesiedelt
				sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die
				Schriftarten zu nutzen. Ein Stylesheet ist eine Formatvorlage,
				über die man einfach und schnell z.B. das Design bzw. die
				Schriftart einer Webseite ändern kann.
			</p>
			<p>
				Die Font-Dateien werden bei Google ein Jahr gespeichert. Google
				verfolgt damit das Ziel, die Ladezeit von Webseiten
				grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf
				die gleichen Schriften verweisen, werden sie nach dem ersten
				Besuch zwischengespeichert und erscheinen sofort auf allen
				anderen später besuchten Webseiten wieder. Manchmal aktualisiert
				Google Schriftdateien, um die Dateigröße zu reduzieren, die
				Abdeckung von Sprache zu erhöhen und das Design zu verbessern.
			</p>
			<h4>
				Wie kann ich meine Daten löschen bzw. die Datenspeicherung
				verhindern?
			</h4>
			<p>
				Jene Daten, die Google für einen Tag bzw. ein Jahr speichert
				können nicht einfach gelöscht werden. Die Daten werden beim
				Seitenaufruf automatisch an Google übermittelt. Um diese Daten
				vorzeitig löschen zu können, müssen Sie den Google-Support auf{" "}
				<a href="https://support.google.com/?hl=de&tid=111478131">
					https://support.google.com/?hl=de&tid=111478131
				</a>{" "}
				kontaktieren. Datenspeicherung verhindern Sie in diesem Fall
				nur, wenn Sie unsere Seite nicht besuchen.
			</p>
			<p>
				Anders als andere Web-Schriften erlaubt uns Google
				uneingeschränkten Zugriff auf alle Schriftarten. Wir können also
				unlimitiert auf ein Meer an Schriftarten zugreifen und so das
				Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und
				weiteren Fragen finden Sie auf{" "}
				<a href="https://developers.google.com/fonts/faq?tid=111478131">
					https://developers.google.com/fonts/faq?tid=111478131
				</a>
				. Dort geht zwar Google auf datenschutzrelevante Angelegenheiten
				ein, doch wirklich detaillierte Informationen über
				Datenspeicherung sind nicht enthalten. Es ist relativ schwierig,
				von Google wirklich präzise Informationen über gespeicherten
				Daten zu bekommen.
			</p>
			<p>
				Welche Daten grundsätzlich von Google erfasst werden und wofür
				diese Daten verwendet werden, können Sie auch auf{" "}
				<a href="https://policies.google.com/privacy?hl=de&tid=111478131">
					https://www.google.com/intl/de/policies/privacy/
				</a>{" "}
				nachlesen.
			</p>
			<h3 id="openstreetmap-datenschutzerklaerung">
				OpenStreetMap Datenschutzerklärung
			</h3>
			<p>
				Wir haben auf unserer Website Kartenausschnitte des
				Online-Kartentools „OpenStreetMap“ eingebunden. Dabei handelt es
				sich um ein sogenanntes Open-Source-Mapping, welches wir über
				eine API (Schnittstelle) abrufen können. Angeboten wird diese
				Funktion von OpenStreetMap Foundation, St John’s Innovation
				Centre, Cowley Road, Cambridge, CB4 0WS, United Kingdom. Durch
				die Verwendung dieser Kartenfunktion wird Ihre IP-Adresse an
				OpenStreetMap weitergeleitet. In dieser Datenschutzerklärung
				erfahren Sie warum wir Funktionen des Tools OpenStreetMap
				verwenden, wo welche Daten gespeichert werden und wie Sie diese
				Datenspeicherung verhindern können.
			</p>
			<h4>Was ist OpenStreetMap?</h4>
			<p>
				Das Projekt OpenStreetMap wurde 2004 ins Leben gerufen. Ziel des
				Projekts ist und war es, eine freie Weltkarte zu erschaffen.
				User sammeln weltweit Daten etwa über Gebäude, Wälder, Flüsse
				und Straßen. So entstand über die Jahre eine umfangreiche, von
				Usern selbst erstellte digitale Weltkarte. Selbstverständlich
				ist die Karte, nicht vollständig, aber in den meisten Regionen
				mit sehr vielen Daten ausgestattet.
			</p>
			<h4>Warum verwenden wir OpenStreetMap auf unserer Website?</h4>
			<p>
				Unsere Website soll Ihnen in erster Linie hilfreich sein. Und
				das ist sie aus unserer Sicht immer dann, wenn man Information
				schnell und einfach findet. Da geht es natürlich einerseits um
				unsere Dienstleistungen und Produkte, andererseits sollen Ihnen
				auch weitere hilfreiche Informationen zur Verfügung stehen.
				Deshalb nutzen wir auch den Kartendienst OpenStreetMap. Denn so
				können wir Ihnen beispielsweise genau zeigen, wie Sie unsere
				Firma finden. Die Karte zeigt Ihnen den besten Weg zu uns und
				Ihre Anfahrt wird zum Kinderspiel.
			</p>
			<h4>Welche Daten werden von OpenStreetMap gespeichert?</h4>
			<p>
				Wenn Sie eine unserer Webseiten besuchen, die OpenStreetMap
				anbietet, werden Nutzerdaten an den Dienst übermittelt und dort
				gespeichert. OpenStreetMap sammelt etwa Informationen über Ihre
				Interaktionen mit der digitalen Karte, Ihre IP-Adresse, Daten zu
				Ihrem Browser, Gerätetyp, Betriebssystem und an welchem Tag und
				zu welcher Uhrzeit Sie den Dienst in Anspruch genommen haben.
				Dafür wird auch Tracking-Software zur Aufzeichnung von
				Userinteraktionen verwendet. Das Unternehmen gibt hier in der
				eigenen Datenschutzerklärung das Analysetool „Piwik“ an.
			</p>
			<p>
				Die erhobenen Daten sind in Folge den entsprechenden
				Arbeitsgruppen der OpenStreetMap Foundation zugänglich. Laut dem
				Unternehmen werden persönliche Daten nicht an andere Personen
				oder Firmen weitergegeben, außer dies ist rechtlich notwendig.
				Der Drittanbieter Piwik speichert zwar Ihre IP-Adresse,
				allerdings in gekürzter Form.
			</p>
			<p>
				Folgendes Cookie kann in Ihrem Browser gesetzt werden, wenn Sie
				mit OpenStreetMap auf unserer Website interagieren:
			</p>
			<p>
				<strong>Name:</strong> _osm_location
				<br />
				<strong>Wert:</strong> 9.63312%7C52.41500%7C17%7CM
				<br />
				<strong>Verwendungszweck:</strong> Das Cookie wird benötigt, um
				die Inhalte von OpenStreetMap zu entsperren.
				<br />
				<strong>Ablaufdatum:</strong> nach 10 Jahren
			</p>
			<p>
				Wenn Sie sich das Vollbild der Karte ansehen wollen, werden Sie
				auf die OpenStreetMap-Website verlinkt. Dort können unter
				anderem folgende Cookies in Ihrem Browser gespeichert werden:
			</p>
			<p>
				<strong>Name:</strong> _osm_totp_token
				<br />
				<strong>Wert:</strong> 148253111478131-2
				<br />
				<strong>Verwendungszweck:</strong> Dieses Cookie wird benutzt,
				um die Bedienung des Kartenausschnitts zu gewährleisten.
				<br />
				<strong>Ablaufdatum:</strong> nach einer Stunde
			</p>
			<p>
				<strong>Name:</strong> _osm_session
				<br />
				<strong>Wert:</strong> 1d9bfa122e0259d5f6db4cb8ef653a1c
				<br />
				<strong>Verwendungszweck:</strong> Mit Hilfe des Cookies können
				Sitzungsinformationen (also Userverhalten) gespeichert werden.
				<br />
				<strong>Ablaufdatum:</strong> nach Sitzungsende
			</p>
			<p>
				<strong>Name:</strong> _pk_id.1.cf09
				<br />
				<strong>Wert:</strong>{" "}
				4a5.1593684142.2.1593688396.1593688396111478131-9
				<br />
				<strong>Verwendungszweck:</strong> Dieses Cookie wird von Piwik
				gesetzt, um Userdaten wie etwa das Klickverhalten zu speichern
				bzw. zu messen.
				<br />
				<strong>Ablaufdatum:</strong> nach einem Jahr
			</p>
			<h4>Wie lange und wo werden die Daten gespeichert?</h4>
			<p>
				Die API-Server, die Datenbanken und die Server von Hilfsdiensten
				befinden sich derzeit im Vereinten Königreich (Großbritannien
				und Nordirland) und in den Niederlanden. Ihre IP-Adresse und
				Userinformationen, die in gekürzter Form durch das
				Webanalysetool Piwik gespeichert werden, werden nach 180 Tagen
				wieder gelöscht.
			</p>
			<h4>
				Wie kann ich meine Daten löschen bzw. die Datenspeicherung
				verhindern?
			</h4>
			<p>
				Sie haben jederzeit das Recht auf Ihre personenbezogenen Daten
				zuzugreifen und Einspruch gegen die Nutzung und Verarbeitung zu
				erheben. Cookies, die von OpenStreetMap möglicherweise gesetzt
				werden, können Sie in Ihrem Browser jederzeit verwalten, löschen
				oder deaktivieren. Dadurch wird allerdings der Dienst nicht mehr
				im vollen Ausmaß funktionieren. Bei jedem Browser funktioniert
				die Verwaltung, Löschung oder Deaktivierung von Cookies etwas
				anders. Im Folgenden finden Sie Links zu den Anleitungen der
				bekanntesten Browser:
			</p>
			<p>
				<a
					href="https://support.google.com/chrome/answer/95647?tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					Chrome: Cookies in Chrome löschen, aktivieren und verwalten
				</a>
			</p>
			<p>
				<a
					href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					Safari: Verwalten von Cookies und Websitedaten mit Safari
				</a>
			</p>
			<p>
				<a
					href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					Firefox: Cookies löschen, um Daten zu entfernen, die
					Websites auf Ihrem Computer abgelegt haben
				</a>
			</p>
			<p>
				<a
					href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					Internet Explorer: Löschen und Verwalten von Cookies
				</a>
			</p>
			<p>
				<a
					href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					Microsoft Edge: Löschen und Verwalten von Cookies
				</a>
			</p>
			<p>
				Wenn Sie mehr über die Datenverarbeitung durch OpenStreetMap
				erfahren wollen, empfehlen wir Ihnen die Datenschutzerklärung
				des Unternehmens unter{" "}
				<a
					href="https://wiki.osmfoundation.org/wiki/Privacy_Policy?tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					https://wiki.osmfoundation.org/wiki/Privacy_Policy.
				</a>
			</p>
			<h3 id="google-analytics-datenschutzerklaerung">
				Google Analytics Datenschutzerklärung
			</h3>
			<p>
				Wir verwenden auf unserer Website das Analyse-Tracking Tool
				Google Analytics (GA) des amerikanischen Unternehmens Google
				Inc. Für den europäischen Raum ist das Unternehmen Google
				Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland)
				für alle Google-Dienste verantwortlich. Google Analytics sammelt
				Daten über Ihre Handlungen auf unserer Website. Wenn Sie
				beispielsweise einen Link anklicken, wird diese Aktion in einem
				Cookie gespeichert und an Google Analytics versandt. Mithilfe
				der Berichte, die wir von Google Analytics erhalten, können wir
				unsere Website und unser Service besser an Ihre Wünsche
				anpassen. Im Folgenden gehen wir näher auf das Tracking Tool ein
				und informieren Sie vor allem darüber, welche Daten gespeichert
				werden und wie Sie das verhindern können.
			</p>
			<h4>Was ist Google Analytics?</h4>
			<p>
				Google Analytics ist ein Trackingtool, das der
				Datenverkehrsanalyse unserer Website dient. Damit Google
				Analytics funktioniert, wird ein Tracking-Code in den Code
				unserer Website eingebaut. Wenn Sie unsere Website besuchen,
				zeichnet dieser Code verschiedene Handlungen auf, die Sie auf
				unserer Website ausführen. Sobald Sie unsere Website verlassen,
				werden diese Daten an die Google-Analytics-Server gesendet und
				dort gespeichert.
			</p>
			<p>
				Google verarbeitet die Daten und wir bekommen Berichte über Ihr
				Userverhalten. Dabei kann es sich unter anderem um folgende
				Berichte handeln:
			</p>
			<ul>
				<li>
					Zielgruppenberichte: Über Zielgruppenberichte lernen wir
					unsere User besser kennen und wissen genauer, wer sich für
					unser Service interessiert.
				</li>
				<li>
					Anzeigeberichte: Durch Anzeigeberichte können wir unsere
					Onlinewerbung leichter analysieren und verbessern.
				</li>
				<li>
					Akquisitionsberichte: Akquisitionsberichte geben uns
					hilfreiche Informationen darüber, wie wir mehr Menschen für
					unser Service begeistern können.
				</li>
				<li>
					Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer
					Website interagieren. Wir können nachvollziehen welchen Weg
					Sie auf unserer Seite zurücklegen und welche Links Sie
					anklicken.
				</li>
				<li>
					Conversionsberichte: Conversion nennt man einen Vorgang, bei
					dem Sie aufgrund einer Marketing-Botschaft eine gewünschte
					Handlung ausführen. Zum Beispiel, wenn Sie von einem reinen
					Websitebesucher zu einem Käufer oder Newsletter-Abonnent
					werden. Mithilfe dieser Berichte erfahren wir mehr darüber,
					wie unsere Marketing-Maßnahmen bei Ihnen ankommen. So wollen
					wir unsere Conversionrate steigern.
				</li>
				<li>
					Echtzeitberichte: Hier erfahren wir immer sofort, was gerade
					auf unserer Website passiert. Zum Beispiel sehen wir wie
					viele User gerade diesen Text lesen.
				</li>
			</ul>
			<h4>Warum verwenden wir Google Analytics auf unserer Webseite?</h4>
			<p>
				Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das
				bestmögliche Service bieten. Die Statistiken und Daten von
				Google Analytics helfen uns dieses Ziel zu erreichen.
			</p>
			<p>
				Die statistisch ausgewerteten Daten zeigen uns ein klares Bild
				von den Stärken und Schwächen unserer Website. Einerseits können
				wir unsere Seite so optimieren, dass sie von interessierten
				Menschen auf Google leichter gefunden wird. Andererseits helfen
				uns die Daten, Sie als Besucher besser zu verstehen. Wir wissen
				somit sehr genau, was wir an unserer Website verbessern müssen,
				um Ihnen das bestmögliche Service zu bieten. Die Daten dienen
				uns auch, unsere Werbe- und Marketing-Maßnahmen individueller
				und kostengünstiger durchzuführen. Schließlich macht es nur
				Sinn, unsere Produkte und Dienstleistungen Menschen zu zeigen,
				die sich dafür interessieren.
			</p>
			<h4>Welche Daten werden von Google Analytics gespeichert?</h4>
			<p>
				Google Analytics erstellt mithilfe eines Tracking-Codes eine
				zufällige, eindeutige ID, die mit Ihrem Browser-Cookie verbunden
				ist. So erkennt Sie Google Analytics als neuen User. Wenn Sie
				das nächste Mal unsere Seite besuchen, werden Sie als
				„wiederkehrender“ User erkannt. Alle gesammelten Daten werden
				gemeinsam mit dieser User-ID gespeichert. So ist es überhaupt
				erst möglich pseudonyme Userprofile auszuwerten.
			</p>
			<p>
				Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden
				Ihre Interaktionen auf unserer Website gemessen. Interaktionen
				sind alle Arten von Handlungen, die Sie auf unserer Website
				ausführen. Wenn Sie auch andere Google-Systeme (wie z.B. ein
				Google-Konto) nützen, können über Google Analytics generierte
				Daten mit Drittanbieter-Cookies verknüpft werden. Google gibt
				keine Google Analytics-Daten weiter, außer wir als
				Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen,
				wenn es gesetzlich erforderlich ist.
			</p>
			<p>Folgende Cookies werden von Google Analytics verwendet:</p>
			<p>
				<strong>Name:</strong> _ga
				<br />
				<strong>Wert: </strong>
				2.1326744211.152111478131-5
				<br />
				<strong>Verwendungszweck:</strong> Standardmäßig verwendet
				analytics.js das Cookie _ga, um die User-ID zu speichern.
				Grundsätzlich dient es zur Unterscheidung der Webseitenbesucher.
				<br />
				<strong>Ablaufdatum:</strong> nach 2 Jahren
			</p>
			<p>
				<strong>Name:</strong> _gid
				<br />
				<strong>Wert: </strong>
				2.1687193234.152111478131-1
				<br />
				<strong>Verwendungszweck:</strong>
				 Das Cookie dient auch zur Unterscheidung der Webseitenbesucher
				<br />
				<strong>Ablaufdatum:</strong> nach 24 Stunden
			</p>
			<p>
				<strong>Name:</strong> _gat_gtag_UA_&lt;property-id&gt;
				<br />
				<strong>Wert:</strong> 1<br />
				<strong>Verwendungszweck:</strong> Wird zum Senken der
				Anforderungsrate verwendet. Wenn Google Analytics über den
				Google Tag Manager bereitgestellt wird, erhält dieser Cookie den
				Namen _dc_gtm_ &lt;property-id&gt;.
				<br />
				<strong>Ablaufdatum: </strong>nach 1 Minute
			</p>
			<p>
				<strong>Name:</strong> AMP_TOKEN
				<br />
				<strong>Wert:</strong> keine Angaben
				<br />
				<strong>Verwendungszweck:</strong> Das Cookie hat einen Token,
				mit dem eine User ID vom AMP-Client-ID-Dienst abgerufen werden
				kann. Andere mögliche Werte weisen auf eine Abmeldung, eine
				Anfrage oder einen Fehler hin.
				<br />
				<strong>Ablaufdatum:</strong> nach 30 Sekunden bis zu einem Jahr
			</p>
			<p>
				<strong>Name:</strong> __utma
				<br />
				<strong>Wert: </strong>
				1564498958.1564498958.1564498958.1
				<br />
				<strong>Verwendungszweck:</strong> Mit diesem Cookie kann man
				Ihr Verhalten auf der Website verfolgen und die Leistung messen.
				Das Cookie wird jedes Mal aktualisiert, wenn Informationen an
				Google Analytics gesendet werden.
				<br />
				<strong>Ablaufdatum:</strong> nach 2 Jahren
			</p>
			<p>
				<strong>Name:</strong> __utmt
				<br />
				<strong>Wert:</strong> 1<br />
				<strong>Verwendungszweck:</strong> Das Cookie wird wie
				_gat_gtag_UA_&lt;property-id&gt; zum Drosseln der
				Anforderungsrate verwendet.
				<br />
				<strong>Ablaufdatum:</strong> nach 10 Minuten
			</p>
			<p>
				<strong>Name:</strong> __utmb
				<br />
				<strong>Wert: </strong>
				3.10.1564498958
				<br />
				<strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet,
				um neue Sitzungen zu bestimmen. Es wird jedes Mal aktualisiert,
				wenn neue Daten bzw. Infos an Google Analytics gesendet werden.
				<br />
				<strong>Ablaufdatum:</strong> nach 30 Minuten
			</p>
			<p>
				<strong>Name:</strong> __utmc
				<br />
				<strong>Wert:</strong> 167421564
				<br />
				<strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet,
				um neue Sitzungen für wiederkehrende Besucher festzulegen. Dabei
				handelt es sich um ein Session-Cookie und wird nur solange
				gespeichert, bis Sie den Browser wieder schließen.
				<br />
				<strong>Ablaufdatum:</strong> Nach Schließung des Browsers
			</p>
			<p>
				<strong>Name:</strong> __utmz
				<br />
				<strong>Wert:</strong>{" "}
				m|utmccn=(referral)|utmcmd=referral|utmcct=/
				<br />
				<strong>Verwendungszweck:</strong> Das Cookie wird verwendet, um
				die Quelle des Besucheraufkommens auf unserer Website zu
				identifizieren. Das heißt, das Cookie speichert, von wo Sie auf
				unsere Website gekommen sind. Das kann eine andere Seite bzw.
				eine Werbeschaltung gewesen sein.
				<br />
				<strong>Ablaufdatum:</strong> nach 6 Monaten
			</p>
			<p>
				<strong>Name:</strong> __utmv
				<br />
				<strong>Wert:</strong> keine Angabe
				<br />
				<strong>Verwendungszweck:</strong> Das Cookie wird verwendet, um
				benutzerdefinierte Userdaten zu speichern. Es wird immer
				aktualisiert, wenn Informationen an Google Analytics gesendet
				werden.
				<br />
				<strong>Ablaufdatum:</strong> nach 2 Jahren
			</p>
			<p>
				<strong>Anmerkung:</strong> Diese Aufzählung kann keinen
				Anspruch auf Vollständigkeit erheben, da Google die Wahl ihrer
				Cookies immer wieder auch verändert.
			</p>
			<p>
				Hier zeigen wir Ihnen einen Überblick über die wichtigsten
				Daten, die mit Google Analytics erhoben werden:
			</p>
			<p>
				<strong>Heatmaps:</strong> Google legt sogenannte Heatmaps an.
				Über Heatmaps sieht man genau jene Bereiche, die Sie anklicken.
				So bekommen wir Informationen, wo Sie auf unserer Seite
				„unterwegs“ sind.
			</p>
			<p>
				<strong>Sitzungsdauer:</strong> Als Sitzungsdauer bezeichnet
				Google die Zeit, die Sie auf unserer Seite verbringen, ohne die
				Seite zu verlassen. Wenn Sie 20 Minuten inaktiv waren, endet die
				Sitzung automatisch.
			</p>
			<p>
				<strong>Absprungrate</strong> (engl. Bouncerate): Von einem
				Absprung ist die Rede, wenn Sie auf unserer Website nur eine
				Seite ansehen und dann unsere Website wieder verlassen.
			</p>
			<p>
				<strong>Kontoerstellung:</strong> Wenn Sie auf unserer Website
				ein Konto erstellen bzw. eine Bestellung machen, erhebt Google
				Analytics diese Daten.
			</p>
			<p>
				<strong>IP-Adresse:</strong> Die IP-Adresse wird nur in
				gekürzter Form dargestellt, damit keine eindeutige Zuordnung
				möglich ist.
			</p>
			<p>
				<strong>Standort:</strong> Über die IP-Adresse kann das Land und
				Ihr ungefährer Standort bestimmt werden. Diesen Vorgang
				bezeichnet man auch als IP- Standortbestimmung.
			</p>
			<p>
				<strong>Technische Informationen:</strong> Zu den technischen
				Informationen zählen unter anderem Ihr Browsertyp, Ihr
				Internetanbieter oder Ihre Bildschirmauflösung.
			</p>
			<p>
				<strong>Herkunftsquelle:</strong> Google Analytics
				beziehungsweise uns interessiert natürlich auch über welche
				Website oder welche Werbung Sie auf unsere Seite gekommen sind.
			</p>
			<p>
				Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das
				Abspielen von Medien (z.B., wenn Sie ein Video über unsere Seite
				abspielen), das Teilen von Inhalten über Social Media oder das
				Hinzufügen zu Ihren Favoriten. Die Aufzählung hat keinen
				Vollständigkeitsanspruch und dient nur zu einer allgemeinen
				Orientierung der Datenspeicherung durch Google Analytics.
			</p>
			<h4>Wie lange und wo werden die Daten gespeichert?</h4>
			<p>
				Google hat Ihre Server auf der ganzen Welt verteilt. Die meisten
				Server befinden sich in Amerika und folglich werden Ihre Daten
				meist auf amerikanischen Servern gespeichert. Hier können Sie
				genau nachlesen wo sich die Google-Rechenzentren befinden:{" "}
				<a
					href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
					target="_blank"
					rel="noopener noreferrer"
				>
					https://www.google.com/about/datacenters/inside/locations/?hl=de
				</a>
			</p>
			<p>
				Ihre Daten werden auf verschiedenen physischen Datenträgern
				verteilt. Das hat den Vorteil, dass die Daten schneller abrufbar
				sind und vor Manipulation besser geschützt sind. In jedem
				Google-Rechenzentrum gibt es entsprechende Notfallprogramme für
				Ihre Daten. Wenn beispielsweise die Hardware bei Google ausfällt
				oder Naturkatastrophen Server lahmlegen, bleibt das Risiko einer
				Dienstunterbrechung bei Google dennoch gering.
			</p>
			<p>
				Standardisiert ist bei Google Analytics eine Aufbewahrungsdauer
				Ihrer Userdaten von 26 Monaten eingestellt. Dann werden Ihre
				Userdaten gelöscht. Allerdings haben wir die Möglichkeit, die
				Aufbewahrungsdauer von Nutzdaten selbst zu wählen. Dafür stehen
				uns fünf Varianten zur Verfügung:
			</p>
			<ul>
				<li>Löschung nach 14 Monaten</li>
				<li>Löschung nach 26 Monaten</li>
				<li>Löschung nach 38 Monaten</li>
				<li>Löschung nach 50 Monaten</li>
				<li>Keine automatische Löschung</li>
			</ul>
			<p>
				Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im
				Monat die Daten gelöscht. Diese Aufbewahrungsdauer gilt für Ihre
				Daten, die mit Cookies, Usererkennung und Werbe-IDs (z.B.
				Cookies der DoubleClick-Domain) verknüpft sind.
				Berichtergebnisse basieren auf aggregierten Daten und werden
				unabhängig von Nutzerdaten gespeichert. Aggregierte Daten sind
				eine Zusammenschmelzung von Einzeldaten zu einer größeren
				Einheit.
			</p>
			<h4>
				Wie kann ich meine Daten löschen bzw. die Datenspeicherung
				verhindern?
			</h4>
			<p>
				Nach dem Datenschutzrecht der Europäischen Union haben Sie das
				Recht, Auskunft über Ihre Daten zu erhalten, sie zu
				aktualisieren, zu löschen oder einzuschränken. Mithilfe des
				Browser-Add-ons zur Deaktivierung von Google
				Analytics-JavaScript (ga.js, analytics.js, dc.js) verhindern
				Sie, dass Google Analytics Ihre Daten verwendet. Das
				Browser-Add-on können Sie unter{" "}
				<a
					href="https://tools.google.com/dlpage/gaoptout?hl=de"
					target="_blank"
					rel="noopener noreferrer"
				>
					https://tools.google.com/dlpage/gaoptout?hl=de
				</a>{" "}
				runterladen und installieren. Beachten Sie bitte, dass durch
				dieses Add-on nur die Datenerhebung durch Google Analytics
				deaktiviert wird.
			</p>
			<p>
				Falls Sie grundsätzlich Cookies (unabhängig von Google
				Analytics) deaktivieren, löschen oder verwalten wollen, gibt es
				für jeden Browser eine eigene Anleitung:
			</p>
			<p>
				<a
					href="https://support.google.com/chrome/answer/95647?tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					Chrome: Cookies in Chrome löschen, aktivieren und verwalten
				</a>
			</p>
			<p>
				<a
					href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					Safari: Verwalten von Cookies und Websitedaten mit Safari
				</a>
			</p>
			<p>
				<a
					href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					Firefox: Cookies löschen, um Daten zu entfernen, die
					Websites auf Ihrem Computer abgelegt haben
				</a>
			</p>
			<p>
				<a
					href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					Internet Explorer: Löschen und Verwalten von Cookies
				</a>
			</p>
			<p>
				<a
					href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					Microsoft Edge: Löschen und Verwalten von Cookies
				</a>
			</p>
			<p>
				Google Analytics ist aktiver Teilnehmer beim EU-U.S. Privacy
				Shield Framework, wodurch der korrekte und sichere Datentransfer
				persönlicher Daten geregelt wird. Mehr Informationen dazu finden
				Sie auf{" "}
				<a
					href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI"
					target="_blank"
					rel="follow noopener noreferrer"
				>
					https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&tid=111478131
				</a>
				. Wir hoffen wir konnten Ihnen die wichtigsten Informationen
				rund um die Datenverarbeitung von Google Analytics näherbringen.
				Wenn Sie mehr über den Tracking-Dienst erfahren wollen,
				empfehlen wir diese beiden Links:{" "}
				<a
					href="http://www.google.com/analytics/terms/de.html"
					target="_blank"
					rel="noopener noreferrer"
				>
					http://www.google.com/analytics/terms/de.html
				</a>{" "}
				und{" "}
				<a
					href="https://support.google.com/analytics/answer/6004245?hl=de"
					target="_blank"
					rel="noopener noreferrer"
				>
					https://support.google.com/analytics/answer/6004245?hl=de
				</a>
				.
			</p>
			<h3 id="google-analytics-ip-anonymisierung">
				Google Analytics IP-Anonymisierung
			</h3>
			<p>
				Wir haben auf dieser Webseite die IP-Adressen-Anonymisierung von
				Google Analytics implementiert. Diese Funktion wurde von Google
				entwickelt, damit diese Webseite die geltenden
				Datenschutzbestimmungen und Empfehlungen der lokalen
				Datenschutzbehörden einhalten kann, wenn diese eine Speicherung
				der vollständigen IP-Adresse untersagen. Die Anonymisierung bzw.
				Maskierung der IP findet statt, sobald die IP-Adressen im Google
				Analytics-Datenerfassungsnetzwerk eintreffen und bevor eine
				Speicherung oder Verarbeitung der Daten stattfindet.
			</p>
			<p>
				Mehr Informationen zur IP-Anonymisierung finden Sie auf 
				<a
					href="https://support.google.com/analytics/answer/2763052?hl=de"
					target="_blank"
					rel="noopener noreferrer"
				>
					https://support.google.com/analytics/answer/2763052?hl=de
				</a>
				.
			</p>
			<h3 id="cloudflare-datenschutzerklaerung">
				Cloudflare Datenschutzerklärung
			</h3>
			<p>
				Wir verwenden auf dieser Webseite Cloudflare der Firma
				Cloudflare, Inc. (101 Townsend St., San Francisco, CA 94107,
				USA), um unsere Webseite schneller und sicherer zu machen. Dabei
				verwendet Cloudflare Cookies und verarbeitet User-Daten.
				Cloudflare, Inc. ist eine amerikanische Firma, die ein Content
				Delivery Network und diverse Sicherheitsdienste anbietet. Diese
				Dienste befinden sich zwischen dem User und unserem
				Hosting-Anbieter. Was das alles genau bedeutet, versuchen wir im
				Folgenden genauer zu erläutern.
			</p>
			<h4>Was ist Cloudflare?</h4>
			<p>
				Ein Content Delivery Network (CDN), wie es die Firma Cloudflare
				bereitstellt, ist nichts anderes als ein Netzwerk aus
				verbundenen Servern. Cloudflare hat auf der ganzen Welt solche
				Server verteilt, um Webseiten schneller auf Ihren Bildschirm zu
				bringen. Ganz einfach gesagt, legt Cloudflare Kopien unserer
				Webseite an und platziert sie auf ihren eigenen Servern. Wenn
				Sie jetzt unsere Webseite besuchen, stellt ein System der
				Lastenverteilung sicher, dass die größten Teile unserer Webseite
				von jenem Server ausgeliefert werden, der Ihnen unsere Webseite
				am schnellsten anzeigen kann. Die Strecke der Datenübertragung
				zu Ihrem Browser wird durch ein CDN deutlich verkürzt. Somit
				wird Ihnen der Content unserer Webseite durch Cloudflare nicht
				nur von unserem Hosting-Server geliefert, sondern von Servern
				aus der ganzen Welt. Besonders hilfreich wird der Einsatz von
				Cloudflare für User aus dem Ausland, da hier die Seite von einem
				Server in der Nähe ausgeliefert werden kann. Neben dem schnellen
				Ausliefern von Webseiten bietet Cloudflare auch diverse
				Sicherheitsdienste, wie den DDoS-Schutz oder die Web Application
				Firewall an.
			</p>
			<h4>Warum wir Cloudflare auf unserer Webseite verwenden?</h4>
			<p>
				Natürlich wollen wir Ihnen mit unserer Webseite das bestmögliche
				Service bieten. Cloudflare hilft uns dabei, unsere Webseite
				schneller und sicherer zu machen. Cloudflare bietet uns sowohl
				Web-Optimierungen als auch Sicherheitsdienste, wie DDoS-Schutz
				und Web-Firewall, an. Dazu gehören auch ein{" "}
				<a
					href="https://de.wikipedia.org/wiki/Reverse_Proxy"
					target="_blank"
					rel="noopener noreferrer"
				>
					Reverse-Proxy
				</a>{" "}
				und das Content-Verteilungsnetzwerk (CDN). Cloudflare blockiert
				Bedrohungen und begrenzt missbräuchliche Bots und Crawler, die
				unsere Bandbreite und Serverressourcen verschwenden. Durch das
				Speichern unserer Webseite auf lokalen Datenzentren und das
				Blockieren von Spam-Software ermöglicht Cloudflare, unsere
				Bandbreitnutzung etwa um 60% zu reduzieren. Das Bereitstellen
				von Inhalten über ein Datenzentrum in Ihrer Nähe und einiger
				dort durchgeführten Web-Optimierungen reduziert die
				durchschnittliche Ladezeit einer Webseite etwa um die Hälfte.
				Durch die Einstellung „I´m Under Attack Mode“ („Ich werde
				angegriffen“-Modus) können laut Cloudflare weitere Angriffe
				abgeschwächt werden, indem eine JavaScript-Rechenaufgabe
				angezeigt wird, die man lösen muss, bevor ein User auf eine
				Webseite zugreifen kann. Insgesamt wird dadurch unsere Webseite
				deutlich leistungsstärker und weniger anfällig auf Spam oder
				andere Angriffe.
			</p>
			<h4>Welche Daten werden von Cloudflare gespeichert?</h4>
			<p>
				Cloudflare leitet im Allgemeinen nur jene Daten weiter, die von
				Webseitenbetreibern gesteuert werden. Die Inhalte werden also
				nicht von Cloudflare bestimmt, sondern immer vom
				Webseitenbetreiber selbst. Zudem erfasst Cloudflare unter
				Umständen bestimmte Informationen zur Nutzung unserer Webseite
				und verarbeitet Daten, die von uns versendet werden oder für die
				Cloudflare entsprechende Anweisungen erhalten hat. In den
				meisten Fällen erhält Cloudflare Daten wie IP-Adresse, Kontakt-
				und Protokollinfos, Sicherheitsfingerabdrücke und Leistungsdaten
				für Webseiten. Protokolldaten helfen Cloudflare beispielsweise
				dabei, neue Bedrohungen zu erkennen. So kann Cloudflare einen
				hohen Sicherheitsschutz für unsere Webseite gewährleisten.
				Cloudflare verarbeitet diese Daten im Rahmen der Services unter
				Einhaltung der geltenden Gesetze. Dazu zählt natürlich auch die
				Datenschutzgrundverordnung (DSGVO).
			</p>
			<p>
				Aus Sicherheitsgründen verwendet Cloudflare auch ein Cookie. Das
				Cookie (__cfduid) wird eingesetzt, um einzelne User hinter einer
				gemeinsam genutzten IP-Adresse zu identifizieren und
				Sicherheitseinstellungen für jeden einzelnen User anzuwenden.
				Sehr nützlich wird dieses Cookie beispielsweise, wenn Sie unsere
				Webseite aus einem Lokal benutzen, in dem sich eine Reihe
				infizierter Computer befinden. Wenn aber Ihr Computer
				vertrauenswürdig ist, können wir dies anhand des Cookies
				erkennen. So können Sie, trotz infizierter PCs in Ihrem Umfeld,
				ungehindert und sorgenfrei durch unsere Webseite surfen. Wichtig
				zu wissen ist auch noch, dass dieses Cookie keine
				personenbezogenen Daten speichert. Dieses Cookie ist für die
				Cloudflare-Sicherheitsfunktionen unbedingt erforderlich und kann
				nicht deaktiviert werden.
			</p>
			<h4>Cookies von Cloudflare</h4>
			<p>
				<strong>Name:</strong> __cfduid
				<br />
				<strong>Wert:</strong>
				 d798bf7df9c1ad5b7583eda5cc5e78111478131-3
				<br />
				<strong>Verwendungszweck:</strong> Sicherheitseinstellungen für
				jeden einzelnen Besucher
				<br />
				<strong>Ablaufdatum:</strong> nach einem Jahr
			</p>
			<p>
				Cloudflare arbeitet auch mit Drittanbietern zusammen. Diese
				dürfen personenbezogene Daten nur unter Anweisung der Firma
				Cloudflare und in Übereinstimmung mit den Datenschutzrichtlinien
				und anderer Vertraulichkeits- und Sicherheitsmaßnahmen
				verarbeiten. Ohne explizite Einwilligung von uns gibt Cloudflare
				keine personenbezogenen Daten weiter.
			</p>
			<h4>Wie lange und wo werden die Daten gespeichert?</h4>
			<p>
				Cloudflare speichert Ihre Informationen hauptsächlich in den USA
				und im Europäischen Wirtschaftsraum. Cloudflare kann die oben
				beschriebenen Informationen aus der ganzen Welt übertragen und
				darauf zugreifen. Im Allgemeinen speichert Cloudflare Daten auf
				User-Ebene für Domains in den Versionen Free, Pro und Business
				für weniger als 24 Stunden. Für Enterprise Domains, die
				Cloudflare Logs (früher Enterprise LogShare oder ELS) aktiviert
				haben, können die Daten bis zu 7 Tage gespeichert werden. Wenn
				allerdings IP-Adressen bei Cloudflare Sicherheitswarnungen
				auslösen, kann es zu Ausnahmen der oben angeführten
				Speicherungsdauer kommen.
			</p>
			<h4>
				Wie kann ich meine Daten löschen bzw. die Datenspeicherung
				verhindern?
			</h4>
			<p>
				Cloudflare bewahrt Daten-Protokolle nur solange wie nötig auf
				und diese Daten werden auch in den meisten Fällen innerhalb von
				24 Stunden wieder gelöscht. Cloudflare speichert auch keine
				personenbezogenen Daten, wie beispielsweise Ihre IP-Adresse. Es
				gibt allerdings Informationen, die Cloudflare als Teil seiner
				permanenten Protokolle auf unbestimmte Zeit speichert, um so die
				Gesamtleistung von Cloudflare Resolver zu verbessern und etwaige
				Sicherheitsrisiken zu erkennen. Welche permanenten Protokolle
				genau gespeichert werden, können Sie auf{" "}
				<a href="https://www.cloudflare.com/application/privacypolicy/">
					https://www.cloudflare.com/application/privacypolicy/
				</a>{" "}
				nachlesen. Alle Daten, die Cloudflare sammelt (temporär oder
				permanent), werden von allen personenbezogenen Daten bereinigt.
				Alle permanenten Protokolle werden zudem von Cloudflare
				anonymisiert.
			</p>
			<p>
				Cloudflare geht in ihrer Datenschutzerklärung darauf ein, dass
				sie für die Inhalte, die sie erhalten nicht verantwortlich sind.
				Wenn Sie beispielsweise bei Cloudflare anfragen, ob sie Ihre
				Inhalte aktualisieren oder löschen können, verweist Cloudflare
				grundsätzlich auf uns als Webseitenbetreiber. Sie können auch
				die gesamte Erfassung und Verarbeitung Ihrer Daten durch
				Cloudflare komplett unterbinden, indem Sie die Ausführung von
				Script-Code in Ihrem Browser deaktivieren oder einen
				Script-Blocker in Ihren Browser einbinden.
			</p>
			<p>
				Cloudflare ist aktiver Teilnehmer beim EU-U.S. Privacy Shield
				Framework, wodurch der korrekte und sichere Datentransfer
				persönlicher Daten geregelt wird. Mehr Informationen dazu finden
				Sie auf{" "}
				<a
					href="https://www.privacyshield.gov/participant?id=a2zt0000000GnZKAA0&tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					https://www.privacyshield.gov/participant?id=a2zt0000000GnZKAA0
				</a>
				.<br />
				Mehr Informationen zum Datenschutz bei Cloudflare finden Sie auf{" "}
				<a
					href="https://www.cloudflare.com/de-de/privacypolicy/?tid=111478131"
					target="_blank"
					rel="noopener noreferrer"
				>
					https://www.cloudflare.com/de-de/privacypolicy/
				</a>
			</p>
			<p>
				Quelle: Erstellt mit dem Datenschutz Generator von{" "}
				<a
					href="https://www.adsimple.at/online-marketing/"
					title="AdSimple® Online Marketing und Webdesign"
					rel="follow noopener noreferrer"
					target="_blank"
				>
					AdSimple® Online Marketing
				</a>{" "}
				in Kooperation mit{" "}
				<a
					href="https://www.aboutbusiness.at/"
					target="_blank"
					rel="follow noopener noreferrer"
				>
					aboutbusiness.at
				</a>
			</p>
		</div>
	);
};

export default Privacy;
