import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface ISelectBoxProps {
	selected: boolean;
	label: string;
	compact?: boolean;
	onChange: (checked: boolean) => void;
}

const SelectBox = ({
	selected,
	label,
	compact = false,
	onChange
}: ISelectBoxProps) => {
	return (
		<div
			className={classNames(
				styles.boxContainer,
				{ [styles.compact]: compact },
				{
					[styles.selected]: selected
				}
			)}
			onClick={() => onChange(!selected)}
		>
			<div className={styles.icon}>
				<FontAwesomeIcon icon={selected ? faCheck : faTimes} />
			</div>
			<div className={styles.label}>{label}</div>
		</div>
	);
};

export default SelectBox;
