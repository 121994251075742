import React, { useState, useEffect } from "react";
import { Fade as Hamburger } from "hamburger-react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDrumstickBite } from "@fortawesome/free-solid-svg-icons";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useMediaQuery } from "react-responsive";

import styles from "./styles.module.scss";
import { DayOfWeek } from "../../utils";
import MainContent from "../main-content";
import Imprint from "../imprint";
import Privacy from "../privacy";

interface IMenuProps {
	selectedDaysOfWeek: DayOfWeek[];
	onSelectedDaysOfWeekChange: (
		checked: boolean,
		dayOfWeek: DayOfWeek
	) => void;
}

enum Page {
	Main,
	Imprint,
	Privacy
}

const Menu = ({
	selectedDaysOfWeek,
	onSelectedDaysOfWeekChange
}: IMenuProps) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [currentPage, setCurrentPage] = useState(Page.Main);

	const isPhone = useMediaQuery({ query: "(max-width: 37.5em)" });

	useEffect(() => {
		setMenuOpen(!isPhone);
	}, []);

	const pages = [
		{
			page: Page.Main,
			content: (
				<MainContent
					selectedDaysOfWeek={selectedDaysOfWeek}
					onSelectedDaysOfWeekChange={onSelectedDaysOfWeekChange}
					onImprintClicked={() => setCurrentPage(Page.Imprint)}
					onPrivacyClicked={() => setCurrentPage(Page.Privacy)}
				/>
			)
		},
		{
			page: Page.Imprint,
			content: <Imprint onBackClicked={() => setCurrentPage(Page.Main)} />
		},
		{
			page: Page.Privacy,
			content: <Privacy onBackClicked={() => setCurrentPage(Page.Main)} />
		}
	];

	return (
		<>
			<PerfectScrollbar
				options={{ suppressScrollX: true }}
				className={classNames(styles.menu, {
					[styles.menuActive]: menuOpen
				})}
			>
				<div className={styles.headerContainer}>
					<h1>
						Halbe <FontAwesomeIcon icon={faDrumstickBite} /> Henn
					</h1>
				</div>
				{pages.map((x) => (
					<div
						key={x.page}
						className={classNames(
							styles.contentContainer,
							{ [styles.pageActive]: x.page === currentPage },
							{
								[styles.pageInactive]: x.page !== currentPage
							}
						)}
					>
						{x.content}
					</div>
				))}
			</PerfectScrollbar>
			<div className={classNames(styles.burgerContainer)}>
				<Hamburger
					toggled={menuOpen}
					toggle={setMenuOpen}
					size={18}
					duration={0.3}
					rounded
				/>
			</div>
		</>
	);
};

export default Menu;
